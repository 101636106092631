<template>
  <div class="draft-box-container">
    <div class="adManagement-container">
      <div
        class="table-top-bar"
        v-if="!ids.length"
      >
        <el-dropdown
          split-button
          type="primary"
          size="small"
          :disabled="!accountId || !accountId.length"
          @command="handleCommand"
          @click="handleCommandClick"
        >
          {{ adType }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="创建标准广告"> 创建标准广告 </el-dropdown-item>
            <!-- <el-dropdown-item command="创建商品广告">创建商品广告</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button type="primary" size="small" @click="createStandardAd">新建标准广告</el-button> -->
        <el-form
          :inline="true"
          size="small"
        >
          <!-- <el-form-item label="">
            <el-input
              v-model="formInline.keyword"
              placeholder="请输入关键字查询"
            ></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-radio-group v-model="formInline.uploadStatusLabel">
              <el-radio-button label="全部"></el-radio-button>
              <el-radio-button label="草稿"></el-radio-button>
              <el-radio-button label="上传成功"></el-radio-button>
              <el-radio-button label="上传中"></el-radio-button>
              <el-radio-button label="上传失败"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button
              type="text"
              icon="el-icon-refresh"
              @click="refresh"
              :disabled="disabled"
            >
              刷新
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="toolsBar"
        v-else
      >
        <div class="toolsCon">
          <p>已选{{ ids.length }}项</p>
          <span></span>
          <el-button
            type="primary"
            @click="f_openBulk('草稿编辑', ids)"
            size="mini"
          >
            草稿编辑
          </el-button>
          <el-button
            type="primary"
            @click="uploadToFb"
            size="mini"
          >
            上传广告系列
          </el-button>
          <!--          <el-button type="primary" @click="editDrafBox('adset')" size="mini">-->
          <!--            编辑广告组-->
          <!--          </el-button>-->
          <!--          <el-button type="primary" @click="draftMove" size="mini">-->
          <!--            草稿转移-->
          <!--          </el-button>-->
        </div>
        <div class="close">
          <i
            class="el-icon-close"
            @click="clearIds"
          ></i>
        </div>
      </div>
    </div>
    <div>
      <el-table
        :data="draftList"
        ref="multipleTable"
        row-key="uniqueId"
        border
        @selection-change="handleSelectionChange"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :cell-class-name="checkbox"
        :height="720"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          v-if="formInline.level == 'campaign'"
        ></el-table-column>
        <el-table-column
          label="草稿箱ID"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column
          label="广告系列名称"
          width="320"
        >
          <template slot-scope="scope">
            <div class="nameWrap">
              <p>{{ scope.row.name }}</p>
              <!-- <div class="tools">
                <span>
                  <i class="el-icon-view"></i>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="edit(scope.row.level, 'preview', scope.row.id)"
                  >
                    预览
                  </el-button>
                </span>
                <span v-if="scope.row.level == 'campaign' && scope.row.uploadStatus != 'UPLOADED'">
                  <i class="el-icon-delete"></i>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="dele(scope.row.id)"
                  >
                    删除
                  </el-button>
                </span>
              </div> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="360"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.createTime }}
              <span v-if="scope.row.uploadTime"> (上传于：{{ scope.row.uploadTime }}) </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="dateType"
          label="状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.uploadStatus == 'DRAFT'
                  ? '草稿'
                  : scope.row.uploadStatus == 'UPLOADED'
                  ? '上传成功'
                  : scope.row.uploadStatus == 'UPLOADING'
                  ? '上传中'
                  : '上传失败'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sourceType"
          label="来源"
          width="120"
        >
          <template slot-scope="scope">
            {{ getOriginLabel(scope.row.sourceType) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="errorMsg"
          label="描述"
          width="400"
        ></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]"
          :page-size="10"
          :current-page.sync="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 批量编辑弹窗 -->
    <my-dialog :myDialogObj="visibleChooseType">
      <bulk
        ref="draftBox"
        @close="f_close"
        :accountId="accountId"
      ></bulk>
    </my-dialog>
    <!-- 批量编辑草稿 -->
    <multiEdit
      :multiEditDialogVisible.sync="multiEditDialogVisible"
      :ids="editIds"
      :currentSelect="currentSelect"
      :accountId="accountId"
      @getList="getList"
    />
    <!-- 编辑广告组 -->
    <!-- 编辑广告 -->
    <!-- 编辑草稿 -->
    <!-- 草稿转移 -->
  </div>
</template>

<script>
import MyDialog from '@/views/google/createGgAd/common/myDialog/index.vue';
import bulk from '@/views/google/bulkCopy/components/bulk/index.vue';
// import draftMove from "./draftMove";
// import EditAdGroup from "@/views/adManagement/createAd/components/editAdGroup.vue";
// import editCampain from "@/views/adManagement/createAd/components/editAdSeries";
// import editAds from "@/views/adManagement/createAd/components/editAd";
// import editDraft from "@/views/adManagement/components/editCopy/components/edit";
import multiEdit from '@/views/google/adManagement/multiEdit/index.vue';
import { getDraftCampaignListApi, uploadDraftByIdsApi, uploadDraft } from '@/api/google/adManagement';
export default {
  props: {
    accountId: {
      type: [String, Array],
      default: [],
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
  },
  components: { MyDialog, bulk, multiEdit },
  data() {
    return {
      uploadDraft,
      visibleChooseType: {
        title: '批量复制',
        dialogVisible: false,
        width: '80%',
        top: '10vh',
      },
      draftMoveVisible: false,
      msg: '草稿箱',
      formInline: {
        level: 'campaign',
        uploadStatus: null,
        uploadStatusLabel: '全部',
      },
      uploadShow: false,
      ids: [],
      draftList: [],
      editCampainShow: false,
      editGroupShow: false,
      editAdsShow: false,
      page: 1,
      pageSize: 10,
      total: 0,
      initData: this.$store.getters.initData,
      disAbled: false,
      campaignId: 0,
      adSetId: 0,
      adId: 0,
      adType: '创建标准广告',
      disabled: false,
      loading: false,
      batchEditShow: false,
      currentSelect: [],
      level: 'adset',
      //   批量编辑
      multiEditDialogVisible: false, //批量编辑弹窗
      editIds: [],
    };
  },
  watch: {
    '$store.getters.fbAccountId': function (v, ov) {
      this.fbAccountId = v;
    },
    accountId() {
      this.getList();
    },
    formInline: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    // 打开批量编辑弹窗
    async f_openBulk(title, ids) {
      this.multiEditDialogVisible = true;
      this.editIds = ids;
    },
    // 关闭
    f_close() {
      this.visibleChooseType.dialogVisible = false;
    },
    closeDraftMoveRefreshList() {
      this.draftMoveVisible = false;
      this.refresh();
    },
    draftMove() {
      this.draftMoveVisible = true;
    },
    // 关闭
    close(v) {
      this[v] = false;
    },
    // 点击创建广告类型
    handleCommandClick() {
      this.handleCommand(this.adType);
    },
    // 点击创建广告类型
    handleCommand(v) {
      // console.log(v);
      this.adType = v;
      if (v == '创建标准广告') {
        this.createStandardAd({ name: 'createGoogle' });
      }
    },
    // 点击编辑草稿
    editDrafBox(level) {
      this.level = level;
      this.batchEditShow = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    // 监听选择
    handleSelectionChange(v) {
      console.log('cgl----handleSelectionChange', v);
      this.currentSelect = v;
      this.ids = v.map((item) => item.id);
    },
    async refresh() {
      this.disabled = true;
      await this.getList();
      this.$message({
        type: 'success',
        message: '刷新成功',
      });
      setTimeout(() => {
        this.disabled = false;
      }, 2000);
    },
    getUploadStatus(label) {
      const labelList = [
        {
          label: '全部',
          value: null,
        },
        {
          label: '草稿',
          value: 'DRAFT',
        },
        {
          label: '上传成功',
          value: 'UPLOADED',
        },
        {
          label: '上传中',
          value: 'UPLOADING',
        },
        {
          label: '上传失败',
          value: 'UPLOAD_FAIL',
        },
      ];
      return labelList.find((item) => item.label === label)?.value;
    },
    getList() {
      if (!this.accountId || !this.accountId.length) return;
      const uploadStatus = this.getUploadStatus(this.formInline.uploadStatusLabel);
      const params = {
        pageNum: this.page,
        pageSize: this.pageSize,
        uploadStatus: uploadStatus,
        accountIds: this.accountId,
      };
      this.loading = true;
      return getDraftCampaignListApi(params).then((res) => {
        this.loading = false;
        this.draftList = res.data?.list || [];
        this.total = res.data?.total || 0;
      });
    },
    checkbox(row) {
      if ((row.row.level === 'adset' || row.row.level === 'ad') && row.columnIndex === 0) {
        return 'mycell';
      }
    },
    // 清除已经选择的项
    clearIds() {
      this.ids = [];
      this.$refs.multipleTable.clearSelection();
    },
    // 点击新建标准广告
    createStandardAd(router) {
      this.$router.push(router);
      /**
       * todo 创建广告
       */
    },
    // 点击预览或者编辑
    edit(toolsType, id) {
      // 预览
      if (toolsType == 'preview') {
        this.disAbled = true;
      } else {
        // 编辑
        this.disAbled = false;
      }
      /**
       * todo 编辑 || 预览
       */
    },
    // 删除草稿系列
    dele(id) {
      // let ids = id?id:this.ids.join(',')
      let formData = new FormData();
      formData.append('draftCampaignId', id);
      this.$confirm('确定删除当前选中广告系列吗？', '删除提示')
        .then((res) => {
          /**
           * todo 删除接口
           */
        })
        .catch((res) => {});
    },
    // 上传广告到google
    uploadToFb() {
      this.$confirm('确定将选中的广告系列上传到google吗？', '上传提示')
        .then(() => {
          this.$showLoading();
          uploadDraftByIdsApi(this.ids)
            .then((res) => {
              if (res.code == 0) {
                this.$message({
                  type: 'success',
                  message: '提交成功，后台上传中',
                });
              }
            })
            .finally(() => {
              this.$hideLoading();
            });
        })
        .catch((res) => {});
    },
    getOriginLabel(val) {
      const labelMap = {
        CREATE: '【标准创建】',
        COPY: '【快速复制】',
        MOBILE_COPY: '【手机端复制】',
      };
      return labelMap[val] || '--';
    },
  },
  mounted() {
    this.getList();
    // this.getInitData();
  },
};
</script>
<style lang="scss">
.editAdsCon {
  position: relative;
}
.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 6666;
  background: rgba(255, 255, 255, 0.4);
  top: 0;
}
.mycell .el-checkbox__input {
  display: none;
}
.page {
  height: 50px;
  padding: 20px;
}
.el-drawer.rtl {
  outline: none;
  * {
    outline: none;
  }
}
.drawerCon {
  height: calc(100vh - 150px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
.demo-drawer__footer {
  text-align: center;
  padding: 15px;
  // background: #efefef;
  border-top: 1px solid #ddd;
}
</style>
<style lang="scss" scoped>
.draft-box-container {
  .el-table {
    margin-top: 10px;
    .nameWrap {
      width: 86%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 48px;
      &:hover {
        .tools {
          display: block;
          position: absolute;
          height: 48px;
          right: 0px;
          top: 12px;
          background: #f5f7fa;
          span {
            &:hover {
              .el-button {
                display: inline-block;
              }
              i {
                display: none;
              }
            }
          }
        }
      }
      .tools {
        display: none;
        span {
          display: inline-block;
          width: 50px;
          text-align: center;
          i {
            display: inline;
          }
          .el-button {
            display: none;
          }
        }
      }
    }
  }
  // padding: 0 16px;
  .toolsBar {
    margin: 10px 0;
    background: rgb(232, 241, 255);
    line-height: 32px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #409eff;
    font-size: 12px;
    .close {
      cursor: pointer;
    }
    .toolsCon {
      display: flex;
      align-items: center;
      p {
        background: #409eff;
        line-height: 28px;
        border-radius: 14px;
        padding: 0 10px;
        font-size: 12px;
        color: #fff;
        margin-right: 10px;
      }
      .el-button {
        margin-left: 15px;
      }
    }
  }
}
.table-top-bar {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 40px;
  .el-form {
    margin-left: 30px;
  }
  .el-form--inline .el-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }
}
</style>
