<template>
  <div class="account_tree_google p-2">
    <el-input
      class="filter_input_google"
      placeholder="输入关键字进行过滤"
      size="medium"
      v-model.trim="filterText"
      clearable
      @keydown.native.enter="f_getTreeData"
      @blur="f_getTreeData"
    >
    </el-input>
    <el-tree
      show-checkbox
      :data="treeData"
      node-key="rowKey"
      :key="keyTree"
      :filter-node-method="filterNode"
      :check-on-click-node="true"
      auto-expand-parent
      :default-expanded-keys="[ids]"
      :class="isRadio ? 'radio-tree' : ''"
      :props="treeProps"
      :load="getMcc"
      highlight-current
      :check-strictly="checkStrictly || isRadio"
      lazy
      ref="tree"
      @check="checkChange"
    >
      <span
        class="cust-tree-node"
        slot-scope="{ node, data }"
        :id="`self_${data.id}`"
      >
        <el-tooltip
          v-if="data._credentialAccount && data._credentialAccount.invalid"
          class="item"
          effect="dark"
          :content="data._credentialAccount.error"
          placement="top-end"
        >
          <span class="el-icon-error"></span>
        </el-tooltip>
        <span>
          {{ data.name }} 【{{ data.id }}】
          {{ data._credentialAccount && data._credentialAccount.hide ? '已隐藏' : '' }}</span
        >
        <span>
          <!-- <el-link class="create-ad" type="info" @click="() => createAd(data)">
          创建广告
        </el-link> -->
          <el-dropdown>
            <span class="el-dropdown-link"> 更多<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                command="create"
                @click.native="() => f_handleClickMore('create', data)"
                v-if="data.canNotManagerClient && showCreate"
                >创建广告</el-dropdown-item
              >
              <el-dropdown-item
                command="changename"
                @click.native="() => f_handleClickMore('changename', data)"
                >修改名称</el-dropdown-item
              >
              <el-dropdown-item
                command="hide"
                @click.native="() => f_handleClickMore('hide', data)"
                >隐藏</el-dropdown-item
              >
              <el-dropdown-item
                command="show"
                @click.native="() => f_handleClickMore('show', data)"
                v-if="data._credentialAccount && data._credentialAccount.hide"
                >显示</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </span>
    </el-tree>
  </div>
</template>
<script>
import { searchAccountNew, hideCredentialAccount, batchUpdate } from '@/api/google/account.js';
const _ = require('lodash');

export default {
  props: {
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    isRadio: {
      type: Boolean,
      default: false,
    },
    showCreate: {
      type: Boolean,
      default: false,
    },
    credentialAccountHide: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      ids: '',
      keyTree: new Date().getTime(),
      filterText: '',
      keyword: '',
      treeProps: {
        children: 'zones',
        label: 'name',
        isLeaf: 'canNotManagerClient',
      },
      treeData: [], //远程模糊搜索数据
      mccList: [],
      initGetDataFlag: true
    };
  },
  computed: {
    showHideMode() {
      return this.credentialAccountHide !== false;
    },
  },
  //   watch: {
  //     filterText: _.debounce(function (val) {
  //       // this.$refs.tree.filter(val);
  //       this.keyword = val.trim();
  //       if (this.keyword) {
  //         this.f_getTreeData();
  //       } else {
  //         this.ids = 0;
  //         this.keyTree = new Date().getTime();
  //       }
  //     }, 500),
  //   },
  methods: {
    // 获取远程模糊搜索过滤出来的树的数据
    async f_getTreeData() {
      if (localStorage.getItem('googleAccountId') && this.isRadio && this.initGetDataFlag) {
        this.filterText = localStorage.getItem('googleAccountId')
      }
      let param = {
        keyword: this.filterText,
        pageSize: 200,
        page: 1,
        searchDetail: false,
        parentMccid: 0,
        invalidParam: 1, // 无效参数，可以随便删
      };
      this.$showLoading();
      try {
        let res = await searchAccountNew(param);
        this.$hideLoading();
        if (res.code == 0) {
            this.treeData = res.data.list.map((item) => {
                return {
                    id: item.accountId,
                    name: item.accountName,
                    canManagerClient: item.canManagerClient,
                    canNotManagerClient: !item.canManagerClient,
                    rowKey: item.credentialId + '-' + item.accountId + '-' + Date.now().toString(36) + Math.random().toString(36).slice(2),
                    utmSourceConfig: item.utmSourceConfig,
                    isSetup: item.hasSetup,
                    ctsCtStatus: item.ctsCtStatus,
                    _credentialAccount: {
                    adaccount: {
                        id: item.accountId,
                        name: item.accountName,
                        canManagerClient: item.canManagerClient,
                    },
                    hide: '',
                    error: '',
                    invalid: item.invalid,
                    },
                };
            });
            if (localStorage.getItem('googleAccountId') && this.isRadio && this.initGetDataFlag) {
            if (this.treeData.length > 0) {
                const firstItem = this.treeData[0];
                const tree = this.$refs.tree;
                // 设置第一个节点为当前选中节点
                this.$nextTick(() => {
                  tree.setCurrentKey(firstItem.rowKey, true);
                });
                this.checkChange(this.treeData[0], null)
            }
            }
            if (!this.initGetDataFlag) {
              localStorage.removeItem('googleAccountId')
            }
            this.initGetDataFlag = false;
        } else {
          return false;
        }
      } catch {
        this.$hideLoading();
      }
      
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 更多操作
    f_handleClickMore(command, data) {
      console.log(command, '更多操作---', data);
      if (command == 'create') {
        this.$store.commit('initData/setAccountIdGoogle', data.id);
        this.$bus.$emit('createAd', data);
      } else if (command === 'hide') {
        this.f_goHide(data, true);
      } else if (command === 'show') {
        this.f_goHide(data, false);
      } else if (command === 'changename') {
        this.f_goChangename(data);
      }
    },
    f_goHide(row, isHide) {
      let showHideMode = this.showHideMode;
      console.log('showHideMode', showHideMode, 'this.credentialAccountHide', this.credentialAccountHide);
      if (!row._credentialAccount) return;
      const data = {
        ids: [row._credentialAccount.id],
        hide: isHide,
      };
      this.$showLoading();
      hideCredentialAccount(data)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success('设置成功');
            this.$nextTick(() => {
              // document.getElementById(`self_${row.id}`).parentNode.parentNode.style.display = 'none'
              setTimeout(() => {
                row._credentialAccount.hide = isHide;
                if (!showHideMode) {
                  row.hide = isHide;
                }
                this.$forceUpdate();
              }, 1000);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$hideLoading();
        });
    },
    f_goChangename(row) {
      this.$prompt(null, '请输入新账号名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /.+/,
        inputErrorMessage: '格式不正确',
        inputValue: row.name,
      })
        .then(({ value }) => {
          this.f_submitChangename(row, value);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消修改账号名称',
          });
        });
    },
    f_submitChangename(row, newName) {
      if (!newName || newName == row.name) {
        return;
      }
      let data = {
        updates: [
          {
            accountId: row.id,
            name: newName,
          },
        ],
      };
      this.$showLoading();
      batchUpdate(data)
        .then((res) => {
          this.$hideLoading();
          if (res.code == 0) {
            this.$message.success('设置成功');
            row.name = newName;
          } else {
            this.$message.error('设置失败：' + res.comment);
          }
        })
        .catch((err) => {
          this.$message.error('设置失败');
          this.$hideLoading();
        });
    },
    // 获取左侧树状结构
    getMcc(node, resolve) {
      console.log(node, resolve);
      let parentMccid = node.level === 0 ? 0 : node.key.split('-')[1];
      let data = {
        page: 1,
        parentMccid: parentMccid,
        pageSize: 200,
        credentialAccountHide: this.credentialAccountHide,
        searchDetail: false,
        invalidParam: 2, // 无效参数，可以随便删
      };
      this.$showLoading();
      searchAccountNew(data).then((res) => {
        this.$hideLoading();
        const newData = res.data.list.map((item) => {
          return {
            id: item.accountId,
            name: item.accountName,
            canManagerClient: item.canManagerClient,
            canNotManagerClient: !item.canManagerClient,
            rowKey: item.credentialId + '-' + item.accountId + '-' + Date.now().toString(36) + Math.random().toString(36).slice(2),
            utmSourceConfig: item.utmSourceConfig,
            _credentialAccount: {
              adaccount: {
                id: item.accountId,
                name: item.accountName,
                canManagerClient: item.canManagerClient,
              },
              hide: '',
              error: '',
              invalid: item.invalid,
            },
          };
        });
        this.mccList = newData;
        console.log('accounttree-mcclist--001', this.mccList);
        resolve(newData);
        if (localStorage.getItem('googleAccountId') && this.initGetDataFlag) {
          this.f_getTreeData()
        }
      });
    },
    checkChange(a, b) {
      console.log(a, b, '9999999999999999999999');
      if (this.isRadio) {
        this.$refs.tree.setCheckedKeys([a.id]);
        if (a.canNotManagerClient) {
          this.$emit('check', [a.id], a);
          this.$store.commit('initData/setAccountIdGoogle', a.id);
          this.$store.commit('google/setCreateAdAccount', a);
          localStorage.setItem('googleAccountId', a.id);
        }
        return;
      }
      this.$emit(
        'check',
        b.checkedKeys.map((v) => v.split('-')[1]),
        b.checkedNodes[0],
      );
    },
    createAd(data) {
      this.$store.commit('initData/setAccountIdGoogle', data.id);
      this.$bus.$emit('createAd', data);
    },
    selecDefaultItem() {
      if (this.treeData.length > 0) {
        const firstItem = this.treeData[0];
        const tree = this.$refs.tree;
        // 设置第一个节点为当前选中节点
        tree.setCurrentKey(firstItem.rowKey);
        // 调用点击节点的处理方法
        this.checkChange();
      }
    },
  },
  created() {
    this.initGetDataFlag = true;
  },
  mounted() {
    // console.log('accounttree-mcclist--001', this.mccList)
    // setTimeout(() => {
    //   console.log('accounttree-mcclist--002', this.mccList)
    // }, 5000)
    // this.selecDefaultItem()
  },
};
</script>
<style lang="scss" scoped>
.account_tree_google {
  height: 100%;
  overflow-y: auto;
  ::v-deep.filter_input_google {
    padding: 5px 10px;
    .el-input__suffix {
      right: 10px;
    }
  }
  ::v-deep.el-tree {
    height: 90%;
    overflow-y: auto;
  }
}
::v-deep.el-dropdown-link {
  color: #409eff;
  font-size: 10px;
}
::v-deep.radio-tree {
  .el-checkbox__inner {
    border-radius: 50%;
    display: none;
  }
}
::v-deep .el-tree-node__content {
  align-items: flex-start;
  padding: 4px 0;
  padding-right: 8px;
  height: auto;
}
.cust-tree-node {
  font-size: 13px;
  word-break: break-all;
  white-space: break-spaces;
}
.create-ad {
  font-size: 12px;
  &:hover {
    color: #409eff;
    &::after {
      border-color: #409eff !important;
      background: #409eff !important;
    }
  }
}
</style>
